export class DropDownType {
	public Value: number;
	public Name: string;

	constructor(name: string, type: number) {
		this.Value = type || type === 0 ? type : 0;
		if (name) {
			const num = name.match(/\d+/);

			if (num) {
				const stringBefore = typeof name.charAt((num.index || 0) - 1);

				if (stringBefore === 'string') {
					name = name ? name.replace(num[0], ' ' + num[0]) : name;
				}
			}
		}

		const nameSplit = name ? name.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g) : '';

		if (nameSplit!.length > 1) {
			name = '';
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < nameSplit!.length; i++) {
				name += nameSplit![i] + ' ';
			}
		}

		this.Name = name ? name.trim() : name;
	}
}
